import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import BsForm from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { Formik, Field as FkField, Form as FkForm } from 'formik'
import { Trash, Pencil, Stars } from 'react-bootstrap-icons'
import ConfirmModal from '../../../ConfirmModal/ConfirmModal'
import firebase from 'firebase/app'
import isURL from 'validator/es/lib/isURL'
import escapeStringRegexp from 'escape-string-regexp'
import './LinkCard.css'

const generateRandomName = require('project-name-generator');


class LinkCard extends React.Component {

  /**
   * props ->
   *   link
   */

  constructor(props) {
    super(props)
    
    this.state = {
      isEditing: this.props.link.isNewLink,
      isNewLinkMode: this.props.link.isNewLink,
      isSaving: false,
      showModal: false,
      cancelValues: undefined,
      isDisabled: !this.props.link.enabled
    }

    this.defaultValues = {
      name: this.props.link.name || '',
      destination: this.props.link.destination || '',
      passcode: this.props.link.passcode || '',
      // enabled: this.props.link.enabled
    }

    // console.log(this.defaultValues)
  }

  validateCard = async (values) => {
    const errors = {}
    
    if (!isURL(this.absoluteLink(values.name))) { errors.name = 'Link must be a valid URL path' }
    // if (this.state.isNewLinkMode) {
      const matchingLinksSnaps = await firebase.firestore().collection('links')
        .where('user_id', '==', firebase.auth().currentUser.uid)
        .where('domain', '==', this.props.link.domain)
        .where('name', '==', '/' + values.name)
        .get()
      if (!(matchingLinksSnaps.empty ||
          (matchingLinksSnaps.docs.length === 1 && matchingLinksSnaps.docs[0].id === this.props.link.id))) {
        errors.name = 'Link name already in use'
      }
    // }

    if (!values.destination) { errors.destination = 'Destination is required' }
    else if (!isURL(values.destination)) { errors.destination = 'Destination must be a valid URL' }

    const pwRegexp = new RegExp(`[a-zA-Z0-9${escapeStringRegexp(`\`!"#$%&'()*+,-./:;<=>?@[]^_{|}~`)}]*`)
    if (!values.passcode.match(pwRegexp)) { errors.passcode = 'Passcode can contain letters, numbers, and punctuation' }
    
    return errors
  }

  startEdit = (values) => {
    console.log('cancel values', values)
    this.setState({
      isEditing: true,
      cancelValues: { ...values }
    })
  }

  fillRandomName = (values, setValues) => {
    const randomName = generateRandomName().dashed
    setValues({
      ...values,
      name: randomName
    }, false)
  }

  saveEdit = async (updatedValues) => {
    console.log('saving')
    this.setState({ isSaving: true })
    // combine initial link with new values
    const link = {
      ...this.props.link,
      ...updatedValues,
      user_id: firebase.auth().currentUser.uid
    }
    link.name = '/' + link.name
    link.enabled = !this.state.isDisabled
    delete link.isNewLink
    // console.log('save edit', link)
    // save to db
    const data = { ...link }
    delete data.id
    // console.log(data)
    await firebase.firestore().collection('links').doc(link.id).set(data)
    // update parent add link state if needed
    if (this.state.isNewLinkMode) {
      this.props.finishAddLink(link)
    }
    // end saving, editing, and new link mode
    this.setState({
      isSaving: false,
      isEditing: false,
      isNewLinkMode: false
    })
  }

  cancelEdit = (setValues) => {
    if (this.state.isNewLinkMode) {
      this.props.finishAddLink()
    }
    else {
      setValues(this.state.cancelValues, false)
      this.setState({
        isEditing: false,
        cancelValues: undefined
      })
    }
  }

  enableLink = async (link, enabled) => {
    // console.log('enable/ disable', link.id, enabled)
    this.setState({ isDisabled: !enabled })
    await firebase.firestore().collection('links').doc(link.id)
      .set({ enabled: enabled }, { merge: true })
  }

  deleteLink = async (link) => {
    await firebase.firestore().collection('links').doc(link.id).delete()
    this.props.removeLink(link)
    this.setState({ showModal: false })
  }
  
  absoluteLink = (relativeLink) => `http://${this.props.link.domain}/${relativeLink}`

  // urlOrNull = (url) => (this.state.isDisabled ? null : url)

  render() {
    return (
      <Card className="mb-3">
        <Card.Body
          className={
            "p-3 " +
            (this.state.isDisabled ? "text-black-50" : "")
        }>
          <Formik
            initialValues={this.defaultValues}
            validate={this.validateCard}
            onSubmit={this.saveEdit}
          >
            {({ values, setValues, errors, touched }) => (
              <>
                <div className="mb-2">
                  <BsForm as={FkForm}>
                    { this.state.isEditing && Object.keys(errors).length > 0 &&
                      <Row>
                        <Col>
                          <div className="text-danger small mb-2">
                            { Object.entries(errors)
                                .filter(([fieldName, error]) => !!touched[fieldName])
                                .map(([fieldName, error]) => <div>{error}</div>)
                            }
                          </div>
                        </Col>
                      </Row>
                    }
                    <Row className="mb-2 font-weight-bold">
                      <Col>
                        { this.state.isEditing ?
                          <div className="d-flex">
                            <InputGroup size="sm" className="mr-2">
                              <InputGroup.Prepend>
                                <InputGroup.Text>{this.props.link.domain + '/'}</InputGroup.Text>
                              </InputGroup.Prepend>
                              <BsForm.Control as={FkField} name="name" placeholder="URL path for this link (blank for root link)"/>
                            </InputGroup>
                            <Button
                              size="sm"
                              variant="light"
                              onClick={() => this.fillRandomName(values, setValues)}
                              style={{width: "8rem"}}
                              >
                              <Stars />&nbsp;Random!
                            </Button>
                          </div>
                          :
                          (this.state.isDisabled ? 
                              <span>{this.props.link.domain}{values.name ? '/' + values.name : ''}</span>
                            :
                            <a
                              href={this.absoluteLink(values.name)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {this.props.link.domain}{values.name ? '/' + values.name : ''}
                            </a>
                          )
                        }
                      </Col>
                    </Row>
                    <Row className={this.state.isEditing ? "mb-1" : ""}>
                      <Col lg={2} md={3}>
                        Destination:
                        </Col>
                      <Col>
                        {this.state.isEditing ?
                          <BsForm.Control as={FkField} size="sm" name="destination" placeholder="Destination URL for this link"/>
                          :
                          ( this.state.isDisabled ? 
                            <span>{values.destination}</span>
                            :
                            <a
                              href={values.destination}
                              target="_blank"
                              rel="noreferrer"
                            >
                            {values.destination}
                            </a>
                          )
                        }
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={2} md={3}>
                        Passcode:
                        </Col>
                      <Col>
                        {this.state.isEditing ?
                          <BsForm.Control as={FkField} size="sm" name="passcode" placeholder="Passcode for this link (optional)" />
                          :
                          <span>{values.passcode}</span>
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        { this.state.isEditing ?
                          <>
                            <Button variant="light" size="sm" className="mr-2" onClick={() => this.cancelEdit(setValues)}>Cancel</Button>
                            <Button className="mr-2" size="sm" type="submit">Save</Button>
                            { this.props.isSaving && <Spinner animation="border" /> }
                          </>
                          :
                          <div className="d-flex">
                            <Button size="sm" variant="light" className="mr-2">
                              <BsForm.Check
                                type="switch"
                                id={this.props.link.id + '-enable'}
                                checked={!this.state.isDisabled}
                                onChange={e => this.enableLink(this.props.link, e.currentTarget.checked)}
                                className="mr-2 pointer"
                                label={this.state.isDisabled ? 'Disabled' : 'Enabled'}
                              />
                            </Button>
                            <Button
                              size="sm"
                              className="mr-2"
                              variant="light"
                              onClick={() => this.startEdit(values)}
                              disabled={this.state.isDisabled}
                              >
                                <Pencil /> Edit
                            </Button>
                            <Button
                              size="sm"
                              className="mr-2"
                              variant="light"
                              onClick={() => this.setState({ showModal: true })}
                              disabled={this.state.isDisabled}
                              >
                                <Trash /> Delete
                            </Button>
                          </div>
                        }
                      </Col>
                    </Row>
                  </BsForm>
                </div>
                
                <ConfirmModal
                  show={this.state.showModal}
                  message={<span>Really delete <code>{this.props.link.domain}{values.name}</code>?</span>}
                  handleConfirm={() => this.deleteLink(this.props.link) }
                  handleCancel={() => this.setState({ showModal: false })}
                />
              </>
            )}
          </Formik>
        </Card.Body>
      </Card>
    )
  }
}

export default LinkCard