import React from 'react'
import { NavLink } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import firebase from 'firebase/app'
import 'firebase/auth'

class Navigation extends React.Component {

  render() {
    return (
      <Navbar expand="lg">
        <Navbar.Brand as={NavLink} to="/" className="mr-5">📞 Switchboard</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          { this.props.isSignedIn &&
            <>
              <Nav className="mr-auto">
                <Nav.Link as={NavLink} to="/links" activeClassName="text-dark">Configure Links</Nav.Link>
                <Nav.Link as={NavLink} to="/domain" activeClassName="text-dark">Add Domain</Nav.Link>
              </Nav>
              <Nav>
                  <Nav.Link onClick={() => firebase.auth().signOut()}>Sign Out</Nav.Link>            
              </Nav>
            </>
          }
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Navigation