import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

class ConfirmModal extends React.Component {

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleCancel}>
        <Modal.Header>
          <Modal.Title>Confirm action</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.handleCancel}>
            Cancel
          </Button>
          <Button onClick={this.props.handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ConfirmModal