import React from 'react'
import { Link } from 'react-router-dom'
import firebase from 'firebase/app'

class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            domains: []
        }
    }

    componentDidMount = async () => {
        const domainSnaps = await firebase.firestore().collection('domains')
            .where('user_id', '==', firebase.auth().currentUser.uid)
            .orderBy('name')
            .get()

        const domains = domainSnaps.docs.map(ds => ds.get('name'))
        console.log(domains)

        this.setState({ domains: domains })
    }

    render() {
        const user = firebase.auth().currentUser

        return (
            <div>
                <p>Welcome, {user.displayName} ({user.email})!</p>
                { this.state.domains.length > 0 ?
                    <>
                        <p>You have {this.state.domains.length} {this.state.domains.length === 1 ? 'domain' : 'domains'} registered: {this.state.domains.map(d => <div><code>{d}</code></div>)}</p>
                        <p>Click <Link to="/links">here</Link> to configure your links, or click <Link to="/domain">here</Link> to add a new domain.</p>
                    </>
                    :
                    <p>You have no domains registered yet.  Click <Link to="/domain">here</Link> to add one.</p>
                }
            </div>
        )
    }
}

export default Home