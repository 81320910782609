import React from 'react'
import Button from 'react-bootstrap/Button'
import BootstrapForm from 'react-bootstrap/Form'
import { Formik, Field as FormikField, Form as FormikForm } from 'formik'
import isValidDomain from 'is-valid-domain'
import ProgressMessage from './ProgressMessage/ProgressMessage'
import firebase from 'firebase/app'
import { Calculator, Check2Circle } from 'react-bootstrap-icons'

class AddDomain extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      domain: undefined,
      verificationCode: '<placeholder>',
      generateCodeShowProgress: false,
      generateCodeStatus: undefined,
      verifyAShowProgress: false,
      verifyAStatus: undefined,
      verifyTxtShowProgress: false,
      verifyTxtStatus: undefined
    }
  }

  validateDomainInput = (values) => {
    const errors = {}
    if (!values.domain) {
      errors.domain = 'Domain is required'
    }
    else if ( !isValidDomain(values.domain, {subdomain: false}) ) {
      errors.domain = 'Enter a valid domain only'
    }
    return errors
  }

  generateCode = async (values) => {
    this.setState({
      domain: values.domain,
      generateCodeShowProgress: true,
      generateCodeStatus: undefined })

    // console.log('form submitted', values)
    const idToken = await firebase.auth().currentUser.getIdToken()
    const res = await fetch('/.netlify/functions/c10GenerateCode', {
      method: 'post',
      body: JSON.stringify({
        domain: values.domain,
        // user_id: firebase.auth().currentUser.uid
      }),
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    })
    // console.log('generate code api response', res)

    if (res.ok) {
      const data = await res.json()
      console.log('data', data)
      this.setState({
        verificationCode: data.token,
        generateCodeStatus: 'ok'
      })
    }
    else {
      this.setState({
        domain: undefined,
        verificationCode: '<placeholder>',
        generateCodeStatus: 'error'
      })
    }
  }

  verifyDomain = async () => {
    this.setState({
      verifyAShowProgress: true,
      verifyAStatus: undefined,
      verifyTxtShowProgress: true,
      verifyTxtStatus: undefined
     })

    // TODO parallelize

    // verify ownership
    const idToken = await firebase.auth().currentUser.getIdToken()
    const resTxt = await fetch('/.netlify/functions/c10VerifyDomainTxt', {
      method: 'post',
      body: JSON.stringify({
        domain: this.state.domain,
        // user_id: firebase.auth().currentUser.uid
      }),
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    })
    console.log('verify TXT api response', resTxt)

    if (resTxt.ok) {
      const dataTxt = await resTxt.json()
      console.log('data', dataTxt)
      if (dataTxt.verified) {
        this.setState({ verifyTxtStatus: 'ok' })
      }
      else {
        this.setState({ verifyTxtStatus: 'error' })
      }
    }
    else {
      this.setState({ verifyTxtStatus: 'error' })
    }
      
    // check domain points to switchboard
    const resA = await fetch('/.netlify/functions/c10VerifyDomainA', {
      method: 'post',
      body: JSON.stringify({domain: this.state.domain}),
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    })
    console.log('verify A api response', resA)

    if (resA.ok) {
      const dataA = await resA.json()
      console.log('data', dataA)
      if (dataA.verified) {
        this.setState({ verifyAStatus: 'ok' })
      }
      else {
        this.setState({ verifyAStatus: 'error' })
      }
    }
    else {
      this.setState({ verifyAStatus: 'error' })
    }

    
  }

  registrationSuccess = () => this.state.generateCodeShowProgress && this.state.generateCodeStatus !== 'error'

  verificationSuccess = () => (
    (this.state.verifyAShowProgress && this.state.verifyAStatus !== 'error') && 
    (this.state.verifyTxtShowProgress && this.state.verifyTxtStatus !== 'error')
  )

  render() {
    return (
      <div>
        <div className="mt-4">
          <h5>1. Generate a verification code</h5>
          <p>This single-use code can be used to verify ownership of your domain and register it with Switchboard.
            Enter your root domain below, without any protocol, subdomain, or path (e.g., <code>johndoe.chat</code>, not <code>https://www.johndoe.chat</code>).</p>
          <Formik
            initialValues={{ domain: '' }}
            validate={this.validateDomainInput}
            onSubmit={this.generateCode}
            >
              {({errors, touched}) => (          
                <div>
                  <BootstrapForm inline as={FormikForm}>
                      <BootstrapForm.Control id="domain" name="domain" type="text" placeholder="Domain name" size="sm" className="mr-2" as={FormikField}/>
                      <Button type="submit" size="sm" className="mr-2" disabled={this.registrationSuccess()}><Calculator /> Generate code</Button>
                      {touched.domain && errors.domain && <div className="text-danger small">{errors.domain}</div>}
                  </BootstrapForm>
                  { this.state.generateCodeShowProgress && <ProgressMessage message="Generating verification code" status={this.state.generateCodeStatus} /> }
                </div>
              )}
          </Formik>
        </div>
        <div className="mt-5">
          <h5>2. Update DNS settings with your domain registrar</h5>
          <p>This step points your domain to Switchboard servers and proves you own your domain.  These steps are completely reversible in case you change your mind later.
            The process is slightly different for each registrar's website, but in general you should:</p>
          <ul>
            <li>Set the A record to the following IP address: <code>{process.env.REACT_APP_C10_ROUTER_IP}</code></li>
            <li>Create a TXT record with host <code>@</code> (if required) and the following value: <code>switchboard_verify={this.state.verificationCode}</code></li>
            <li>(Optional) If it doesn't already exist, create a CNAME record that redirects the <code>www</code> subdomain to the root domain, usually represented by the <code>@</code> symbol</li>
          </ul>
        </div>
        <div className="mt-5">
          <h5>3. Verify and register your domain</h5>
          <p>This completes the verification process, registers your domain with Switchboard, and checks that it's set up correctly.
            (It may take several minutes for the DNS changes you made in step 2 to take effect. If verification fails, wait a few minutes and try again.)</p>
          <Button size="sm" onClick={this.verifyDomain} disabled={!this.registrationSuccess() || this.verificationSuccess()}><Check2Circle /> Verify</Button>
          { this.state.verifyTxtShowProgress && <ProgressMessage message={`Verifying ownership of ${this.state.domain} (TXT record) and registering with Switchboard`} status={this.state.verifyTxtStatus} /> }
          { this.state.verifyAShowProgress && <ProgressMessage message={`Verifying that ${this.state.domain} points to Switchboard (A record)`} status={this.state.verifyAStatus} /> }
        </div>
      </div>
    )
  }
}

export default AddDomain