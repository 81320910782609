import React from 'react'
import firebase from 'firebase/app'
import DomainTable from './DomainTable/DomainTable'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'


class ConfigureLinks extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      domains: []
    }
  }

  componentDidMount = async () => {
    await this.fetchDomains()
  }

  fetchDomains = async () => {
    this.setState({ isFetching: true })

    try {
      const domainsP = firebase.firestore().collection('domains')
        .where('user_id', '==', firebase.auth().currentUser.uid)
        .orderBy('name')
        .get()
      
      const linksP = firebase.firestore().collection('links')
        .where('user_id', '==', firebase.auth().currentUser.uid)
        .orderBy('name')
        .get()

      const [domainSnaps, linkSnaps] = await Promise.all([domainsP, linksP])

      const links = linkSnaps.docs.map(ls => {
        const link = Object.assign({id: ls.id}, ls.data())
        // remove leading /
        link.name = link.name.substring(1)
        return link
      })
      const domains = domainSnaps.docs.map(ds => {
        const d = ds.data()
        d.id = ds.id
        d.links = links.filter(l => l.domain === d.name)
        return d
      })
      console.log('domains', domains, 'links', links)
    
      this.setState({
        domains: domains
      })
    }
    catch (e) {
      console.log(e)
    }
    finally {
      this.setState({ isFetching: false })
    }
  }

  render() {
    return (
      <div>
        <Card>
          {/* TODO working here */}
        </Card>
        { this.state.domains.length > 0 ?
            this.state.domains.map(d => (
            <DomainTable
              key={d.id}
              domain={d} />
          ))
          :
          <p>You have no domains registered yet.  Click <Link to="/domain">here</Link> to add one.</p>
        }
      </div>
    )
  }
}

export default ConfigureLinks