import React from 'react'
import firebase from 'firebase/app'
import '../firebaseInit'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import Container from 'react-bootstrap/Container'
import { BrowserRouter } from 'react-router-dom'
import Navigation from './Navigation/Navigation'
import Content from './Content/Content'

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isSignedIn: undefined
    }
  }

  firebaseUiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    }
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      this.setState({ isSignedIn: !!user })
    })
  }

  componentWillUnmount() {
    this.unregisterAuthObserver()
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Container>
          { this.state.isSignedIn &&
            <>
              <Navigation 
                isSignedIn={this.state.isSignedIn}
              />
              <hr />
              <div className="px-3 mt-4">
                  <Content />
              </div>
            </>
          }
          { this.state.isSignedIn !== undefined && !this.state.isSignedIn &&
            <>
              <div className="text-center display-4 mt-5 mb-4">
                📞 Switchboard
              </div>
              <div className="text-center mb-5" style={{"font-size": "1.25rem"}}>
                Set up your <code>.chat</code> domain!
              </div>
              <StyledFirebaseAuth
                uiConfig={this.firebaseUiConfig}
                firebaseAuth={firebase.auth()}
                />
            </>
          }
          </Container>
        </div>
      </BrowserRouter>
    )
  }
}

export default App