import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { Check, X } from 'react-bootstrap-icons'

class ProgressMessage extends React.Component {

  // props -> message, status
  render() {
    return (
      <div className="small mt-1">
        <span className="mr-1">{this.props.message}</span>
        { !this.props.status && <Spinner animation="border" size="sm" /> }
        { this.props.status === 'ok' && <span className="text-success"><Check /> Succeeded</span> }
        { this.props.status === 'error' && <span className="text-danger"><X /> Failed - please retry</span> }
      </div>
    )
  }
}

export default ProgressMessage