import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from '../../Home/Home'
import AddDomain from '../../AddDomain/AddDomain'
import ConfigureLinks from '../../ConfigureLinks/ConfigureLinks'

class Content extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/links">
          <ConfigureLinks />
        </Route>
        <Route path="/domain">
          <AddDomain />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    )
  }
}

export default Content