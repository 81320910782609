import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const config = {
  apiKey: "AIzaSyBa7d96EKtxvFx9juEB9o_-m2nw0gplf0w",
  authDomain: "switchboard-d9e1a.firebaseapp.com",
  projectId: "switchboard-d9e1a",
  storageBucket: "switchboard-d9e1a.appspot.com",
  messagingSenderId: "479415339336",
  appId: "1:479415339336:web:277e90f199c04d485acb7d",
  measurementId: "G-9REQ9Y0DYF"
}

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

// export default firebase.app()