import React from 'react'
import Button from 'react-bootstrap/Button'
import { PlusCircle } from 'react-bootstrap-icons'
import LinkCard from './LinkCard/LinkCard'
import firebase from 'firebase/app'

class DomainTable extends React.Component {

  /**
   * props -->
   *   domain
   * 
   */

  constructor(props) {
    super(props)
    this.state = {
      isAdding: false,
      links: this.props.domain.links
    }
  }

  startAddLink = () => {
    this.setState({ isAdding: true })
    const links = [...this.state.links, {
      id: firebase.firestore().collection('links').doc().id,
      domain: this.props.domain.name,
      enabled: true,
      isNewLink: true
    }]
    // console.log('start add link', links)
    this.setState({ links: links })
  }

  finishAddLink = (link) => {
    const links = [...this.state.links]
    links.pop()
    if (link) { links.push(link) }
    this.setState({
      links: links,
      isAdding: false
    })
  }

  removeLink = async (link) => {
    const links = this.state.links.filter(l => l.id !== link.id)
    this.setState({ links: links })
  }

  render() {
    return (
      <div className="DomainTable mt-4 mb-5">
        <div className="mb-3">
          <h5>{this.props.domain.name}</h5>
        </div>
        <div>
          { this.state.links.map(link => {
              return (
                <LinkCard
                  key={link.id}
                  link={link}
                  finishAddLink={this.finishAddLink}
                  removeLink={this.removeLink} />
              )
            }
          )}
          <Button variant="light" onClick={() => this.startAddLink()} disabled={this.state.isAdding}>
            <PlusCircle /> Add a new link
          </Button>
        </div>
      </div>
    )
  }
}

export default DomainTable